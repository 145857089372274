import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_TheoryBlock = _resolveComponent("TheoryBlock")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_overlay = _resolveComponent("b-overlay")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_ThemeLooperBlock = _resolveComponent("ThemeLooperBlock")!
  const _component_TheoryPreExamBlock = _resolveComponent("TheoryPreExamBlock")!
  const _component_PlaygroundBlock = _resolveComponent("PlaygroundBlock")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!
  const _directive_clickable_mobile = _resolveDirective("clickable-mobile")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader, { "default-href": "/" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, { class: "mb-md-3 mb-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_overlay, {
            id: "overlay-background",
            show: _ctx.loadingStudentEducations,
            rounded: "sm",
            class: "w-100 h-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TheoryBlock, {
                    "student-educations": _ctx.studentEducationWithTheory,
                    "theory-link": 'theory-questions'
                  }, null, 8, ["student-educations"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["show"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, {
        "no-gutter": "",
        class: "mb-md-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            class: "pr-md-2 mb-md-0 mb-3 order-md-0 order-0",
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_ThemeLooperBlock, null, null, 512), [
                [_directive_clickable_mobile, { onMobileClick: _ctx.onClickThemeLooperCard }]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            class: "pl-md-2 mb-md-0 mb-3 order-md-0 order-1",
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_TheoryPreExamBlock, null, null, 512), [
                [_directive_clickable_mobile, { onMobileClick: _ctx.onClickPreliminaryExamCard }]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, { class: "mt-md-2 mb-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_PlaygroundBlock)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}